@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
  --defaultBG: #f3f3f3;
  --defaultGradientColorOne: #EF2F3A;
  --defaultGradientColorTwo: #141414;
  --defaultBtnColor: #EF2F3A;
  --defaultColor: linear-gradient(to right, #EF2F3A, #383839);
  --defaultAuthTheme: linear-gradient(to right, #16101085, #141414);
  --defaultPrimaryColor: #a7060e;
  --defaultDark: #000000;
  --fontFamily: "Raleway", sans-serif;

  --siteHeadingOneFontWeight: bolder;
  --siteHeadingOneFontSize: 18px;
  --tableBtnSize: 18px;

  --tblDeleteBtnColor: #EF2F3A;
  --tblEditBtnColor: #EF2F3A;
}

body {
  font-family: var(--fontFamily) !important;
  /* background-color: var(--defaultBG) !important; */
}

p {
  font-family: var(--fontFamily) !important;
  color: var(--defaultDark) !important;
  font-weight: 600 !important;
  line-height: 25px;
  font-size: 16px;
}

a {
  text-decoration: none !important;
}

.active {
  color: #EF2F3A !important;
}

img {
  width: 100%;
}

h1 {
  font-family: var(--fontFamily) !important;
  font-size: 45px;
  font-weight: 800 !important;
  line-height: 48px;
}

h2 {
  font-family: var(--fontFamily) !important;
  font-size: 40px;
  font-weight: 800 !important;
  line-height: 48px;
}

h3 {
  font-family: var(--fontFamily) !important;
  font-size: 30px;
  font-weight: 800 !important;
  line-height: 48px;
}

h6 {
  line-height: 25px;
}

.logo {
  width: 250px;
}

.auth .logo {
  width: 330px;
}

.login-form .ant-input {
  background: #f6f6f6 !important;
}

.color-primary {
  color: var(--defaultPrimaryColor) !important;
}

.color-theme {
  color: var(--defaultColor) !important;
}

.background-theme {
  background: var(--defaultColor) !important;
}

.background-theme-auth {
  background: var(--defaultAuthTheme) !important;
}

.ant-menu-item-selected {
  color: var(--defaultColor) !important;
  font-weight: 600;
}

.background-theme {
  /* background: var(--defaultColor) !important; */
  /* background: rgb(2, 0, 36); */
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(202, 69, 139, 1) 0%,
      rgb(140, 12, 127) 100%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2rem; */
}

.background-theme>img {
  width: 50%;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid var(--defaultColor) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
  padding: 0 30px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.ant-layout {
  background: var(--defaultBG) !important;
}

.ant-btn-primary {
  border-color: var(--defaultBtnColor) !important;
  background: var(--defaultBtnColor) !important;
}

.ant-menu {
  background: var(--defaultBG) !important;
}

.ant-layout-header {
  line-height: 50px !important;
}

.ant-layout-header {
  padding: 2rem !important;
  background: transparent !important;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-btn {
  font-weight: bold !important;
  font-size: 14px !important;
  height: 50px !important;
  /* line-height: normal!important; */
}

.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-horizontal>.ant-menu-submenu::after {
  bottom: 5px !important;
}

.card {
  border: none !important;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
}

.head-card {
  float: left;
  position: absolute;
  z-index: 99;
}

section {
  /* padding: 2rem 2rem; */
}

.section {
  padding: 5rem 5rem;
}

.text-gray {
  color: #242424 !important;
  font-weight: 400 !important;
}

.text-dark {
  color: #000000 !important;
}

.text-team {
  transform: rotate(-90deg);
  transform-origin: 8rem 10rem;
}

.text-team span {
  font-size: 50px !important;
}

.ant-card-meta-title {
  color: #242424 !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  text-align: center;
}

.ant-card-meta-description {
  color: #202020 !important;
  text-align: center;
}

.membership .ant-list-items .ant-list-item-meta-avatar img {
  width: 20px;
  height: 15px;
}

.ant-list-split .ant-list-item {
  border-bottom: none !important;
}

.custom-card {
  justify-content: center;
}

sup {
  top: -0.1em !important;
}

.bg-head {
  background: var(--defaultColor) !important;
}

.member-btn {
  line-height: 0 !important;
  padding-top: 1.65rem !important;
  padding-bottom: 1.65rem !important;
}

.middle-membership .ant-card {
  background: var(--defaultColor);
}

.middle-membership .ant-card h1 {
  color: #ffffff !important;
}

.middle-membership .ant-card h3 {
  color: #ffffff !important;
}

.middle-membership .ant-card h4 {
  color: #ffffff !important;
}

.middle-membership .ant-card .text-dark {
  color: #ffffff !important;
}

.middle-membership .ant-card .ant-btn {
  background: white !important;
  color: #ec220d;
}

.color-download {
  color: #474a54 !important;
  font-weight: 900 !important;
}

.text-light-gray {
  color: #707070 !important;
  font-weight: 300 !important;
}

.mobiles_ img {
  margin-top: -12rem;
  position: absolute;
  right: -10rem;
}

.memb_ {
  background-image: linear-gradient(#f3f3f3, #fff);
}

::placeholder {
  color: #2e2e2e !important;
}

.ant-input {
  height: 60px;
  background: #e8e8e8 0% 0% no-repeat padding-box !important;
  border: none !important;
}

.ant-input-textarea {
  height: 152px;
  background: #e8e8e8 0% 0% no-repeat padding-box !important;
  border: none !important;
}

.contact-card {
  background: #f5f5f5 !important;
}

.footer {
  background: #343e44 !important;
}

.footer-menu ul {
  display: flex;
}

.footer-menu ul li {
  list-style: none;
}

.footer-menu ul li a {
  color: #fff !important;
  font-weight: 600;
  margin-left: 5rem;
  margin-right: 5rem;
}

.border-top {
  border-top: 1px solid #888 !important;
}

.center {
  justify-content: center;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.login-form-button {
  width: 100%;
  /* height: fit-content !important; */
}

.login-form {
  padding: 0 5rem !important;
}

.common_btn_width {
  height: 32px !important;
}

.pull-right {
  float: right !important;
}

.ant-radio-inner::after {
  background-color: #ec220d !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #ec220d !important;
}

.ant-radio-group {
  font-weight: 800;
}

.modal_form_input {
  color: #000 !important;
}

.ant-input-affix-wrapper {
  background-color: #f6f6f6 !important;
  border: 1px solid #f6f6f6 !important;
  padding: 0 11px !important;
}

.ant-radio-wrapper {
  margin-right: 50px !important;
}

.verification .ant-input {
  font-size: 2.5rem !important;
  text-align: center;
  height: 90px;
  box-shadow: 0px 2px 6px #00000029;
  background: white !important;
  width: 90px;
  color: #bbbbbb !important;
}

.verification ::placeholder {
  font-size: 4rem !important;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff !important;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3) !important;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-sider {
  background: #ffffff !important;
}

.ant-layout-sider {
  /* width: 300px!important; 
  max-width: 300px!important; */
  height: 100vh;
}

.ant-layout-header {
  /* position: fixed; */
  width: 100% !important;
  z-index: 1;
  top: 0;
}

.ant-layout-sider {
  z-index: 2;
}

.site-layout .ant-layout-content {
  top: 0;
  /* margin: 6rem 30px!important; */
  /* position: fixed; */
  width: 90% !important;
}

.ant-layout-sider-children .ant-menu {
  background: #ffffff !important;
}

.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.86);
}

.ant-list-item-meta-title {
  color: rgb(0, 0, 0);
  font-size: 18px !important;
  font-weight: 800;
}

.ant-input-group-addon {
  border: none !important;
}

.anticon-exclamation-circle {
  display: none !important;
}

.ant-popover-buttons {
  display: none !important;
}
button{
  border-radius: 1rem !important;
}
button > span{
  font-size: 14px !important;
}
.ant-popover {
  width: 18% !important;
}

.react-tel-input .form-control {
  margin-left: 0;
  background: #f6f6f6;
  border-right: 1px solid #e3e3e3 !important;
  border-radius: 0 !important;
  line-height: 25px;
  height: 60px !important;
  width: inherit !important;
  outline: none;
  border: none !important;
  border-right-color: currentcolor;
  border-right-style: none !important;
  border-right-width: medium;
}

.react-tel-input .flag-dropdown {
  border: none;
}

.anticon-user {
  color: var(--defaultColor) !important;
  font-size: 2rem;
}

.anticon-bell {
  color: var(--defaultColor) !important;
  font-size: 2rem;
}

.card-cover {
  height: 400px;
  background-position: center !important;
  background-size: cover !important;
}

.price-type {
  background: #37b6abcc;
  border-radius: 2px;
}

.price-type-free {
  background: #fb9f1df4;
  border-radius: 2px;
}

.right {
  justify-content: right;
}

.event-price {
  margin-top: -5rem;
  margin-bottom: 2rem;
}

.event-avtar .ant-avatar {
  float: right !important;
}

.event-locate .anticon {
  color: #c7c7c7;
}

.ant-card {
  box-shadow: 0px 3px 6px #00000029;
}

.search_ .ant-input-group-addon {
  background: #e8e8e8;
}

.search_ .ant-input-search-button {
  background: #e8e8e8;
  border: none;
  box-shadow: none;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: 1px solid #fff !important;
}

.event-tab .ant-tabs-tab {
  padding: 12px 15rem;
}

.event-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--defaultColor) !important;
  font-size: 1.25rem;
  font-weight: 700;
}

.event-tab .ant-tabs-tab-btn {
  color: #4a4a4a !important;
  font-size: 1.25rem;
  font-weight: 600;
}

.event-tab .ant-tabs-ink-bar {
  background: transparent;
}

.event-price_top {
  top: 15px;
  z-index: 999999999;
  position: absolute;
  width: 100%;
  left: 2.5rem;
}

.price-type_top {
  background: rgba(255, 255, 255, 0.4);
  /* opacity: 0.4; */
}

.sidebarLogo {
  height: 50px;
}

.ant-table-wrapper {
  width: 100%;
}

.siteHeadingOne {
  color: var(--defaultGradientColorOne);
  font-weight: var(--siteHeadingOneFontWeight);
  font-size: var(--siteHeadingOneFontSize);
}

.modal_form .ant-input {
  height: 40px;
}

.tableBtns {
  font-size: var(--tableBtnSize);
  cursor: pointer;
}

.tblDeleteBtn {
  color: var(--tblDeleteBtnColor) !important;
}

.tblEditBtn {
  color: var(--tblEditBtnColor) !important;
}

.ant-upload-list-item-actions .anticon-eye {
  display: none;
}

.ant-tooltip-placement-top {
  display: none !important;
}

.siteBreadCrumb {
  margin-bottom: 10px;
}

.suspense_logo {
  width: 20%;
}

.suspense_text {
  font: 600 2rem Raleway;
  text-align: center;
}

.tableImgBox {
  /* border: 1px solid silver; */
}

.tableImgBox img {
  width: 80px;
  height: 70px;
}

/* verification input stylying */

.vi__character {
  width: 80px;
  height: 80px !important;
  border: none !important;
  box-shadow: 0px 2px 6px #00000029;
  line-height: 90px !important;
  background: #fff !important;
  color: #bbbbbb !important;
  margin: 0 5px;
  /* font-size: 3rem !important; */
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.vi__character--selected {
  outline: 2px solid #bbbbbb;
}

.receivecode {
  font-size: 10px;
}

.resendcode {
  font-size: 10px;
  color: var(--defaultGradientColorOne);
  cursor: pointer;
}

.content_padding {
  padding: 20px 20px 0 20px;
}

.ant-tooltip-inner {
  display: none;
}

.text-gray-signup {
  color: #a2a2a2 !important;
  font: normal normal 500 13px/20px Raleway !important;
}

/* Admin Login Styling */
.reset_left_div {
  height: 100vh;
  padding: 10% 0;
}

.inputText {
  position: relative;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: none !important;
  /* background-color: #f6f6f6; */
}

.floating-label {
  position: absolute;
  pointer-events: none;
  bottom: 20px;
  left: 10px;
  transition: 0.2s ease all;
  color: #979898;
  font-size: 13px;
  z-index: 999999;
}

.inputText:focus~.floating-label,
.inputText:not(:focus):valid~.floating-label {
  bottom: 33px;
  left: 10px;
}

.dont_acc {
  position: absolute;
  bottom: 20px;
  left: 35%;
}

/* forgot stylying */
.forgot_left_div {
  height: 100vh;
  padding: 13% 0;
}

.ver_btn_width {
  width: 55%;
  margin: 20px 0;
}

.floating-label-2 {
  bottom: 33px;
}

.ant-input[disabled] {
  color: #000 !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #000 !important;
}

.ant-picker-input>input[disabled] {
  color: #000 !important;
}

.markerTitle {
  font-size: 16px !important;
  font-weight: normal !important;
}

.ancorlabel {
  color: rgba(0, 0, 0, .85) !important;
}

.ancorlabel:hover {
  color: rgba(0, 0, 0, .85) !important;
}

.headerToggle {
  position: absolute;
  font-size: 18px;
  margin-top: -4px;
}

.collapsedLogo {
  width: 60px !important;
}

.previewImageModal {
  height: 300px;
  width: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.subscribed_plan_sec {
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(202, 69, 139, 1) 0%,
      rgb(140, 12, 127) 100%);
  color: #fff;
}

.subscribed_plan_sec .ant-card {
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(202, 69, 139, 1) 0%,
      rgb(140, 12, 127) 100%);
  box-shadow: none !important;
  color: #fff !important;
  border: none !important;
}

.subscribed_plan_sec .subscribed_plan_text {
  color: #fff !important;
}

.subscribed_plan_sec .subsPlanBtns {
  background-color: #fff !important;
  color: var(--defaultBtnColor) !important;
  border: 2px solid var(--defaultBtnColor) !important;
}

.subsPlanBtns {
  width: 70%;
  padding-top: 5% !important;
  padding-bottom: 12% !important;
}

.headerRightSec {
  line-height: 0 !important;
}

.dashboardCard {
  background: var(--defaultColor) !important;
}

.dashboardCard p {
  font-weight: bold;
  font-size: 18px;
  color: #fff !important;
}

.dashboardCard label {
  font-weight: bold;
  font-size: 16px;
  color: #fff !important;
}

.dashboardCard:hover {
  scale: 1.01;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.ant-table-thead .ant-table-cell {
  color: #000;
  font-weight: bold;
}

.tableColBold {
  font-weight: bold;
}

/* Calendar style */
.calendarSec>.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.calendarSec>.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.calendarSec>.notes-month {
  font-size: 28px;
  text-align: center;
}

.calendarSec>.notes-month section {
  font-size: 28px;
}

.ant-picker-calendar-mode-switch {
  display: none !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 40px !important;
}

.events span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* background-color: #e6f7ff; */
  color: #000;
}

.jodit-add-new-line {
  display: none !important;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0;
  display: flex;
  justify-content: center;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, .25);
  border-color: #d9d9d9;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: center;
}